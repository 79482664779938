















import { defineComponent } from '@nuxtjs/composition-api';
import SfHeading from '/storefrontUI/components/components/atoms/SfHeading/SfHeading.vue';
import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'EmptyWishlist',
  components: {
    SfHeading,
    SvgImage,
  },
});
